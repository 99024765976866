import { HttpClientModule } from "@angular/common/http";
import { ModuleWithProviders, NgModule } from "@angular/core";
import { BrowserModule } from "@angular/platform-browser";
import { RouterModule } from "@angular/router";
import { ApolloClientOptions, InMemoryCache, split } from "@apollo/client/core";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { config } from "@fortawesome/fontawesome-svg-core";
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, NamedOptions } from "apollo-angular";
import { HttpLink } from "apollo-angular/http";
import { AccountModule } from "./account/account.module";
import { EditWidgetComponent } from "./edit-widget/edit-widget.component";
import { FooterComponent } from "./footer/components/footer.component";
import { HeaderComponent } from "./header/header.component";
import { HoursModule } from "./hours/hours.module";
import { ImageModule } from "./image/image.module";
import { LazyModule } from "./lazy/lazy.module";
import { Menu2Component } from "./menu2/menu2.component";
import { RouteResolver } from "./route-resolver.service";
import { UtilModule } from "./util/util.module";

@NgModule({
	declarations: [HeaderComponent, FooterComponent, EditWidgetComponent, Menu2Component],
	imports: [
		BrowserModule,
		HttpClientModule,
		RouterModule.forChild([]),
		UtilModule,
		ImageModule,
		AccountModule,
		FontAwesomeModule,
		LazyModule,
		HoursModule,
	],
	exports: [UtilModule, HeaderComponent, FooterComponent, EditWidgetComponent],
	providers: [
		{
			provide: APOLLO_OPTIONS,
			useFactory: (httpLink: HttpLink): ApolloClientOptions<any> => {
				const http = httpLink.create({ uri: "/graphql-node" });

				const ws = new WebSocketLink({
					uri: `wss://${location.host}/graphql-node`,
					options: { reconnect: true, lazy: true },
				});

				const link = split(
					// split based on operation type
					({ query }) => {
						const def = getMainDefinition(query);
						return def.kind === "OperationDefinition" && def.operation === "subscription";
					},
					ws,
					http,
				);

				return {
					cache: new InMemoryCache(),
					link,
					defaultOptions: { query: { fetchPolicy: "no-cache" }, watchQuery: { fetchPolicy: "no-cache" } },
				};
			},
			deps: [HttpLink],
		},
	],
})
export class CoreModule {
	static forRoot(): ModuleWithProviders<CoreModule> {
		return { ngModule: CoreModule, providers: [RouteResolver] };
	}
	constructor() {
		config.autoAddCss = false;
	}
}
