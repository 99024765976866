/// <reference types="googlemaps" />

import { Directive, ElementRef, EventEmitter, Input, NgZone, Output } from "@angular/core";
import { map, shareReplay } from "rxjs/operators";
import { loadScript } from "shared/common";

const SCRIPT_URL =
	"https://maps.googleapis.com/maps/api/js?v=weekly&libraries=places&key=AIzaSyDK6RybE29BiXD8VPd_BtKeE0LuvjaCcYA";
@Directive({ selector: "input[cmInputPlaces]" })
export class InputPlacesDireective {
	@Input() fields: string[] = ["address_components", "geometry"];
	@Input() types: string[] = [];

	@Output() placeChanged = new EventEmitter<google.maps.places.PlaceResult>();

	autocomplete$ = loadScript(SCRIPT_URL).pipe(
		map(() => {
			const autocomplete = new google.maps.places.Autocomplete(this.el.nativeElement, {
				fields: this.fields,
				types: this.types,
			});
			autocomplete.addListener("place_changed", () =>
				this.zone.run(() => this.placeChanged.next(autocomplete.getPlace())),
			);
			return autocomplete;
		}),
		shareReplay(1),
	);

	constructor(private el: ElementRef<HTMLInputElement>, private zone: NgZone) {
		this.autocomplete$.subscribe();
	}
}
