import { PortalModule } from "@angular/cdk/portal";
import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { GoogleMapsComponent } from "./google-maps.component";
import { InputPlacesDireective } from "./input-places.directive";
import { MapPopupDirective } from "./map-popup.directive";

@NgModule({
	declarations: [GoogleMapsComponent, MapPopupDirective, InputPlacesDireective],
	imports: [CommonModule, PortalModule],
	exports: [GoogleMapsComponent, MapPopupDirective, InputPlacesDireective],
})
export class GoogleMapsModule {}
