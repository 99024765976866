import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { RouterModule, Routes, UrlMatcher, UrlMatchResult, UrlSegment } from "@angular/router";
import { ServiceWorkerModule } from "@angular/service-worker";
import { RootComponent } from "@core/app/pages/root/root.component";
import { RouteResolver } from "@core/app/route-resolver.service";
import { environment } from "@core/environments/environment";
import { ToastNoAnimationModule } from "ngx-toastr";
import { ModalModule, PrerenderModule } from "shared";
import { CoreModule } from "./core.module";
import { PAGE_DATA } from "./generated/page-data";

export const routes: Routes = [
	{
		matcher: routeMatcher0 as UrlMatcher,
		loadChildren: () => import("./pages/page-admin/page-admin.module").then((m) => m.PageAdminModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Admin",
			pageTemplateId: 88,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/new-admin).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher1 as UrlMatcher,
		loadChildren: () => import("./pages/page-account/page-account.module").then((m) => m.PageAccountModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Account",
			pageTemplateId: 60,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/my-account)(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher2 as UrlMatcher,
		loadChildren: () => import("./pages/page-home/page-home.module").then((m) => m.PageHomeModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Home",
			pageTemplateId: 39,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/)(\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},

	{
		matcher: routeMatcher4 as UrlMatcher,
		loadChildren: () => import("./pages/page-about/page-about.module").then((m) => m.PageAboutModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "About",
			pageTemplateId: 61,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/about-us)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher5 as UrlMatcher,
		loadChildren: () => import("./pages/page-contact/page-contact.module").then((m) => m.PageContactModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Contact",
			pageTemplateId: 138,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/contact)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher6 as UrlMatcher,
		loadChildren: () => import("./pages/page-services/page-services.module").then((m) => m.PageServicesModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Services",
			pageTemplateId: 298,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/services)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher13 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-services-landscaping/page-services-landscaping.module").then(
				(m) => m.PageServicesLandscapingModule,
			),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Services",
			pageTemplateId: 298,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/services\\/landscaping)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher14 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-services-retaining-wall/page-services-retaining-wall.module").then(
				(m) => m.PageServicesRetainingWallModule,
			),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Services",
			pageTemplateId: 298,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/services\\/retaining-wall)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher15 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-services-patios-walkways/page-services-patios-walkways.module").then(
				(m) => m.PageServicesPatiosWalkwaysModule,
			),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Services",
			pageTemplateId: 298,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/services\\/patios-walkways)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher16 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-services-irrigation/page-services-irrigation.module").then(
				(m) => m.PageServicesIrrigationModule,
			),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Services",
			pageTemplateId: 298,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/services\\/irrigation)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher17 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-services-lawn-maintenance/page-services-lawn-maintenance.module").then(
				(m) => m.PageServicesLawnMaintenanceModule,
			),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Services",
			pageTemplateId: 298,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/services\\/lawn-maintenance)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher18 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-services-hydroseeding/page-services-hydroseeding.module").then(
				(m) => m.PageServicesHydroseedingModule,
			),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Services",
			pageTemplateId: 298,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/services\\/hydroseeding)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher19 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-services-snow-removal/page-services-snow-removal.module").then(
				(m) => m.PageServicesSnowRemovalModule,
			),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Services",
			pageTemplateId: 298,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/services\\/snow-removal)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher20 as UrlMatcher,
		loadChildren: () =>
			import("./pages/page-services-mulching/page-services-mulching.module").then(
				(m) => m.PageServicesMulchingModule,
			),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Services",
			pageTemplateId: 298,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/services\\/mulching)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher11 as UrlMatcher,
		loadChildren: () => import("./pages/page-resources/page-resources.module").then((m) => m.PageResourcesModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Resources",
			pageTemplateId: 338,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/resources)$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher7 as UrlMatcher,
		loadChildren: () => import("./pages/page-products/page-products.module").then((m) => m.PageProductsModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Products",
			pageTemplateId: 497,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/product)(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher8 as UrlMatcher,
		loadChildren: () => import("./pages/page-product/page-product.module").then((m) => m.PageProductModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Product",
			pageTemplateId: 255,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/product\\/(?:[^\\/]+))(?:\\?.*)?$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher9 as UrlMatcher,
		loadChildren: () => import("./pages/page-category/page-category.module").then((m) => m.PageCategoryModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Category",
			pageTemplateId: 292,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/category\\/(.+?))(?:\\/([0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&offer_cat=2&page=3",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher10 as UrlMatcher,
		loadChildren: () => import("./pages/page-crm/page-crm.module").then((m) => m.PageCrmModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "CRM",
			pageTemplateId: 228,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/lead-management).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher12 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Facebook",
			pageTemplateId: 475,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/facebook).*$",
			rewriteTo: "content_page_url=1",
			stmtid: 85,
		},
	},
	{
		matcher: routeMatcher3 as UrlMatcher,
		loadChildren: () => import("./pages/page-page/page-page.module").then((m) => m.PagePageModule),
		resolve: { routeData: RouteResolver },
		data: {
			bodyClass: "Page",
			pageTemplateId: 13,
			// Angular's aot compiler ignores regular expressions, so this needs to be stored as a string and converted
			// to regex where it's used
			regex: "^(\\/.+?)(?:(\\/[0-9]+))?(?:\\?.*)?$",
			rewriteTo: "content_page_url=1&page=2",
			stmtid: 85,
		},
	},
];

export function routeMatcher0(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/new-admin).*$/, segments, true);
}
export function routeMatcher1(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/my-account)(?:\?.*)?$/, segments, false);
}
export function routeMatcher2(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/)(\?.*)?$/, segments, false);
}
export function routeMatcher3(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/.+?)(?:(\/[0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher4(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/about-us)$/, segments, false);
}
export function routeMatcher5(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/contact)$/, segments, false);
}
export function routeMatcher6(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/services)$/, segments, false);
}
export function routeMatcher11(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/resources)$/, segments, false);
}
export function routeMatcher7(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/product)(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher8(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/product\/(?:[^\/]+))(?:\?.*)?$/, segments, false);
}
export function routeMatcher9(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/category\/(.+?))(?:\/([0-9]+))?(?:\?.*)?$/, segments, false);
}
export function routeMatcher10(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/lead-management).*$/, segments, false);
}
export function routeMatcher12(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/facebook).*$/, segments, false);
}
export function routeMatcher13(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/services\/landscaping)$/, segments, false);
}
export function routeMatcher14(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/services\/retaining-wall)$/, segments, false);
}
export function routeMatcher15(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/services\/patios-walkways)$/, segments, false);
}
export function routeMatcher16(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/services\/irrigation)$/, segments, false);
}
export function routeMatcher17(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/services\/lawn-maintenance)$/, segments, false);
}
export function routeMatcher18(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/services\/hydroseeding)$/, segments, false);
}
export function routeMatcher19(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/services\/snow-removal)$/, segments, false);
}
export function routeMatcher20(segments: UrlSegment[]): UrlMatchResult | null {
	return routeMatcher(/^(\/services\/mulching)$/, segments, false);
}

export function routeMatcher(regex: RegExp, segments: UrlSegment[], hasChildRouter: boolean): UrlMatchResult | null {
	if (regex.test("/" + segments.map((x) => x.path).join("/"))) {
		if (hasChildRouter) {
			return { consumed: [segments[0]] };
		} else {
			return { consumed: segments };
		}
	} else {
		return null;
	}
}

@NgModule({
	declarations: [RootComponent],
	imports: [
		CommonModule,
		CoreModule.forRoot(),
		ToastNoAnimationModule.forRoot({ positionClass: "toast-top-center" }),
		PrerenderModule,
		RouterModule.forRoot(routes, {
			initialNavigation: "enabledBlocking",
			scrollPositionRestoration: "enabled",
			anchorScrolling: "enabled",
			onSameUrlNavigation: "reload",
		}),
		ServiceWorkerModule.register("./ngsw-worker.js", { enabled: environment.production }),
		ModalModule,
	],
	providers: [{ provide: "PAGE_DATA", useValue: PAGE_DATA }],
	entryComponents: [RootComponent],
	bootstrap: [RootComponent],
})
export class AppModule {}
