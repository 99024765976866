import { IEnvironmentPartial } from "./environment-types";

export const environment: IEnvironmentPartial = {
	dealerInfo: {
		name: "mike-rose",
		homeBreadcrumb: "Mike Rose Landscape & Irrigation",
		themeData: {
			dealerAccent: null,
		},
	},
};
